import { LoaderFunctionArgs, redirect } from "@remix-run/node";
import { Outlet } from "@remix-run/react";

import { getUser } from "~/lib/auth";

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUser(request);
  if (!user) {
    return redirect("/login");
  }
  if (user.role === "admin") {
    return redirect("/admin");
  }
  return redirect("/shop");
}

export default function Layout() {
  return <Outlet />;
}
